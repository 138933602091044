import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {},
  reducers: {
    setGallery: (state, action) => {
      assign(state, action.payload);
    }
  }
});

export const { setGallery } = gallerySlice.actions;

export const selectGallery = (state) => state.gallery;

export default gallerySlice.reducer;

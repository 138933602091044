import url from 'url';
import omit from 'lodash/omit';

export const formatWithoutTicket = (location) => {
  const urlObject = url.parse(location.href, true);
  return url.format({
    protocol: urlObject.protocol,
    host: urlObject.host,
    pathname: urlObject.pathname,
    query: omit(urlObject.query, 'ticket')
  });
};

export const redirect = (url) => {
  window.location = url;
};

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { http } from '../../../utils/http';
import Stat from './Stat';
import { selectCRMStats, setCRMStats } from '../../../store/crmStats/crmStatsSlice';
import handleApiError from '../../../utils/handleApiError';
import './styles.css';
import messages from '../../../utils/messages';

const fetchCRMStats = async (dispatch) => {
  try {
    const stats = get(await http.get('/crm'), 'data');
    dispatch(setCRMStats(stats));
  } catch (error) {
    handleApiError(error, () =>  dispatch(setCRMStats({ error: true })));
  }
};

const CRMStats = () => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const dispatch = useDispatch();
  const stats = useSelector(selectCRMStats);

  useEffect(() => {
    fetchCRMStats(dispatch);
  }, [dispatch]);

  return <div className="crmStats">
    <div className="header">{t(messages.leadsMonth, { leads: stats.directEmails + stats.ywLeads + stats.boatsLeads + stats.otherLeads })}</div>
    <div className="stats">
      {has(stats, 'directEmails') && <Stat label={t(messages.directEmails)} value={stats.directEmails}/>}
      {has(stats, 'ywLeads') && <Stat label={t(messages.yw)} value={stats.ywLeads}/>}
      {has(stats, 'boatsLeads') && <Stat label={t(messages.boatsdotcom)} value={stats.boatsLeads}/>}
      {has(stats, 'otherLeads') && <Stat label={t(messages.others)} value={stats.otherLeads}/>}
      {isEmpty(stats) && !stats.error && <div title={t(messages.pleaseWait)} className="loading"/>}
      {stats.error && <div title={t(messages.error)} className="warning"/>}
    </div>
    <div className="header">{t(messages.unreadLeads, { leads: stats.totalUnreadLeads })}</div>
    <div className="unread">
      <div className="subHeader">
        <div className="totalHeader">{t(messages.todayLeads)}:</div>
        <div className="total"><div>{stats.todayUnreadLeads}</div></div>
      </div>
      <div className="subHeader">
        <div className="totalHeader">{t(messages.twoThreeDayLeads)}:</div>
        <div className="total"><div>{stats.twoThreeDaysOldLeads}</div></div>
      </div>
      <div className="subHeader">
        <div className="totalHeader">{t(messages.fourAndOlderLeads)}:</div>
        <div className="total"><div>{stats.fourOneEightyDaysOldLeads}</div></div>
      </div>
    </div>
  </div>;
};

export default CRMStats;

import { Helmet } from 'react-helmet-async';
import './styles.css';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';

const { REACT_APP_PROVISIONING_HOST } = process.env;

const Header = ({ user }) => {

  useEffect(() => {
    if (user.id) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.id = 'enterpriseHeaderScript';
      script.src = `${REACT_APP_PROVISIONING_HOST}/provisioning/shared/entnav.seam?currentApp=portal`;
      document.getElementById('enterpriseHeaderContainer').appendChild(script);
    }
  }, [user]);

  if (!user.id) {
    return null;
  }

  const css1 = `${REACT_APP_PROVISIONING_HOST}/provisioning/assets/styles/base.css`;
  const css2 = `${REACT_APP_PROVISIONING_HOST}/provisioning/assets/styles/enterprisenav.css`;
  const css3 = `${REACT_APP_PROVISIONING_HOST}/provisioning/shared/branding.seam`;

  return (
    <div id="header">
      <div id="enterpriseHeaderContainer">
        <div id="enterpriseHeader">
          <Helmet>
            <link rel="stylesheet" href={css1} type="text/css"/>
            <link rel="stylesheet" href={css2} type="text/css"/>
            <link rel="stylesheet" href={css3} type="text/css"/>
          </Helmet>
          <div className="welcomeBar"/>
          <div className="appsLinks">
            <ul>
              <li><a href="/">Home</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.object
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

const listingStatsSlice = createSlice({
  name: 'listingStats',
  initialState: {},
  reducers: {
    setListingStats: (state, action) => {
      assign(state, action.payload);
    }
  }
});

export const { setListingStats } = listingStatsSlice.actions;

export const selectListingStats = (state) => state.listingStats;

export default listingStatsSlice.reducer;

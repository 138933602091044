import { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import messages from '../../../utils/messages';
import { useIntl } from 'react-intl';
import { useClipboard } from 'use-clipboard-copy';

import './styles.css';

const BW_SOURCE_ID = 108653;

const TridentReferral = ({ user }) => {
  const [buttonText, setButtonText] = useState('');
  const timerId = useRef(null);

  const intl = useIntl();
  const t = intl.formatMessage;

  useEffect(() => {
    setButtonText(t(messages.financingButtonText));
  }, [t]);

  const partyId = user.id;
  const referralUrl = `https://www.tridentfunding.com/?purpose=Boat&source=${BW_SOURCE_ID}&subsource=${partyId}`;

  const clipboard = useClipboard();

  const handleCopyToClipboard = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    clipboard.copy(referralUrl);
    setButtonText(t(messages.financingButtonCopiedText));

    timerId.current = setTimeout(() => {
      setButtonText(t(messages.financingButtonText));
    }, 700);
  };

  return (
    <div className="trident-referral-container">
      <img className="tridentLogo" alt="Frame" src="../../../icons/tridentFundingLogo.png" />
      <div>
        <div className="title-text">{t(messages.financingTitle)}</div>
        <div className="subtitle">{t(messages.financingSubtitle)}</div>
      </div>

      <div className="cta-content">
        <div className="cta-button-container">
          <span className="cta-link">{referralUrl}</span>
          <button
            className="cta-button"
            onClick={handleCopyToClipboard}
            data-testid="copy-button"
            data-reporting-click-internal-link-type="click"
            data-reporting-click-internal-link-id="referral - copy link"
          >
            {buttonText}
          </button>
        </div>
        <span className="cta-subject">{t(messages.financingButtonSubject)}</span>
      </div>

      <div className="call-content">
        <span data-testid="call-text" className="call-text">{t(messages.financingCallText1)}
          <a className="call-phone" href="tel:888-356-6005">888-356-6005</a>
          {t(messages.financingCallText2)}</span>
      </div>
    </div>
  );
};

TridentReferral.propTypes = {
  user: PropTypes.object
};

export default TridentReferral;

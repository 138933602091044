import { PropTypes } from 'prop-types';

const magnitudes = [
  { value: 1E9, symbol: 'G' },
  { value: 1E6, symbol: 'M' },
  { value: 1E3, symbol: 'k' }
];
const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

const formatNumber = (number, decimalDigits) => {
  for (const mag of magnitudes) {
    if (number >= mag.value) {
      return (number / mag.value).toFixed(decimalDigits).replace(rx, '$1') + mag.symbol;
    }
  }
  return Math.max(number, 0);
};


const Stat = ({ label, round = true, value }) => {
  return <div className="stat">
    <div className="label">{label}</div>
    {(value || value === 0) && <div className="value">{round ? formatNumber(value, 0) : value}</div>}
  </div>;
};

export default Stat;

Stat.propTypes = {
  label: PropTypes.string,
  round: PropTypes.bool,
  value: PropTypes.number
};

import axios from 'axios';
import url from 'url';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { formatWithoutTicket } from './urlHelpers';

const { REACT_APP_PUBLIC_API_ENDPOINT } = process.env;

const apiLogin = '/login';

const internals = {};

const createClient = () => {
  const http = axios.create({
    baseURL: REACT_APP_PUBLIC_API_ENDPOINT
  });

  http.interceptors.request.use((config) => {
    if (internals.token && !includes(config.url, apiLogin)) {
      config.headers.Authorization = `Bearer ${internals.token}`;
    }
    return config;
  });

  http.interceptors.response.use(null, async (error) => {
    const ticket = get(url.parse(window.location.href, true), 'query.ticket');
    // 401 (Unauthorized) means we didn't send a valid token. If we have a CAS ticket, we can swap that for a token by logging in.
    if (get(error, 'response.status') === 401 && !includes(get(error, 'config.url'), apiLogin) && ticket) {
      const loginResponse = await http.get(apiLogin, { params: { ticket, service: formatWithoutTicket(window.location) } });
      internals.token = get(loginResponse, 'data.token');
      return http.request(error.config);
    }
    throw error;
  });

  return http;
};

export const http = createClient();

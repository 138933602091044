import { useIntl } from 'react-intl';
import messages from '../../utils/messages';
import './styles.css';
import { PropTypes } from 'prop-types';


const Welcome = ({ user }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  if (user.id) {
    return <div id="welcome">{t(messages.welcome, user)}</div>;
  } else if (user.error) {
    return <div id="welcomeError">{t(messages.error)}</div>;
  }
  return <div id="welcomeWait">{t(messages.pleaseWait)}</div>;
};

Welcome.propTypes = {
  user: PropTypes.object
};

export default Welcome;

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { http } from '../../../utils/http';
import { selectListingStats, setListingStats } from '../../../store/listingStats/listingStatsSlice';
import handleApiError from '../../../utils/handleApiError';
import Stat from './Stat';
import './styles.css';
import messages from '../../../utils/messages';

const fetchListingStats = async (dispatch) => {
  try {
    const stats = get(await http.get('/listing-stats'), 'data');
    dispatch(setListingStats(stats));
  } catch (error) {
    handleApiError(error, () =>  dispatch(setListingStats({ error: true })));
  }
};

const ListingStats = () => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const dispatch = useDispatch();
  const stats = useSelector(selectListingStats);

  useEffect(() => {
    fetchListingStats(dispatch);
  }, [dispatch]);

  return <div className="listingStats">
    <div className="header">{t(messages.lastNDays, { n: 90 })}</div>
    {isEmpty(stats) && !stats.error && <div title={t(messages.pleaseWait)} className="loading"/>}
    {stats.error && <div title={t(messages.error)} className="warning"/>}
    <div className="stats">
      {has(stats, 'impressions') && <Stat label={t(messages.statsImpressions)} value={stats.impressions}/>}
      {has(stats, 'views') && <Stat label={t(messages.statsViews)} value={stats.views}/>}
      {has(stats, 'leads') && <Stat label={t(messages.statsLeads)} value={stats.leads}/>}
      {has(stats, 'connections') && <Stat label={t(messages.statsConnections)} value={stats.connections}/>}
      {has(stats, 'listingsAdded') && <Stat label={t(messages.addedListings)} value={stats.listingsAdded} round={false}/>}
      {has(stats, 'listingsRemoved') && <Stat label={t(messages.removedListings)} value={stats.listingsRemoved} round={false}/>}
      {has(stats, 'listingsOffSold') && <Stat label={t(messages.offSoldListings)} value={stats.listingsOffSold} round={false}/>}
    </div>
    <div className="header">{t(messages.total)}</div>
    <div className="stats">
      {has(stats, 'totalOn') && <Stat label={t(messages.onListings)} value={stats.totalOn} round={false}/>}
    </div>
  </div>;
};

export default ListingStats;

import { useEffect } from 'react';
import './styles.css';
import { http } from '../../../utils/http';
import { selectGallery, setGallery } from '../../../store/gallery/gallerySlice';
import { useDispatch, useSelector } from 'react-redux';
import handleApiError from '../../../utils/handleApiError';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import messages from '../../../utils/messages';
import { useIntl } from 'react-intl';


const fetchGalleryData = async (dispatch) => {
  try {
    const gallery = get(await http.get('/gallery'), 'data');
    dispatch(setGallery(gallery));
  } catch (error) {
    handleApiError(error, () => dispatch(setGallery({ error: true })));
  }
};

const Gallery = () => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const dispatch = useDispatch();
  const gallery = useSelector(selectGallery);
  useEffect(() => {
    fetchGalleryData(dispatch);
  }, [dispatch]);


  if (gallery.error) {
    return <div title={t(messages.error)} className="warning" />;
  }
  if (isEmpty(gallery)) {
    return <div title={t(messages.pleaseWait)} className="loading" />;
  }

  return (
    <div className="myGallery">
      {get(gallery, 'boatsdotcom') && <div className="photobox">
        <a href={get(gallery, 'boatsdotcom')} className="boatsdotcomGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>boats.com</label>
        </div>
      </div>
      }

      {get(gallery, 'yw') && <div className="photobox">
        <a href={get(gallery, 'yw')} className="ywGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>YachtWorld</label>
        </div>
      </div>
      }

      {get(gallery, 'btol') && <div className="photobox">
        <a href={get(gallery, 'btol')} className="btolGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Boat Trader</label>
        </div>
      </div>
      }

      {get(gallery, 'boatshop24') && <div className="photobox">
        <a href={get(gallery, 'boatshop24')} className="boatshop24Gallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Boatshop24.com</label>
        </div>
      </div>
      }

      {get(gallery, 'boatshop24uk') && <div className="photobox">
        <a href={get(gallery, 'boatshop24uk')} className="boatshop24ukGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Boatshop24.co.uk</label>
        </div>
      </div>
      }

      {get(gallery, 'adb') && <div className="photobox">
        <a href={get(gallery, 'adb')} className="adbGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Annonces du Bateau</label>
        </div>
      </div>
      }

      {get(gallery, 'boatsandoutboards') && <div className="photobox">
        <a href={get(gallery, 'boatsandoutboards')} className="boatsandoutboardsGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>BoatsandOutboards</label>
        </div>
      </div>
      }

      {get(gallery, 'botentekoop') && <div className="photobox">
        <a href={get(gallery, 'botentekoop')} className="botentekoopGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Botentekoop</label>
        </div>
      </div>
      }

      {get(gallery, 'botenbank') && <div className="photobox">
        <a href={get(gallery, 'botenbank')} className="botenbankGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>BotenBank</label>
        </div>
      </div>
      }

      {get(gallery, 'boot24') && <div className="photobox">
        <a href={get(gallery, 'boot24')} className="boot24Gallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Boot24</label>
        </div>
      </div>
      }

      {get(gallery, 'cosasdebarcos') && <div className="photobox">
        <a href={get(gallery, 'cosasdebarcos')} className="cosasdebarcosGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>Cosas de Barcos</label>
        </div>
      </div>
      }

      {get(gallery, 'botentekoop') && <div className="photobox">
        <a href={get(gallery, 'botentekoop').replace('botentekoop.com', 'yachtfocus.com/en')} className="yachtfocusGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>YachtFocus</label>
        </div>
      </div>
      }

      {get(gallery, 'cosasdebarcos') && <div className="photobox">
        <a href={get(gallery, 'cosasdebarcos').replace('cosasdebarcos', 'topbarcos').replace('inautia', 'topboats')} className="topbarcosGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>TopBarcos</label>
        </div>
      </div>
      }

      {get(gallery, 'adb') && <div className="photobox">
        <a href={get(gallery, 'adb').replace('annoncesbateau.com', 'youboat.com/fr')} className="youboatGallery" target="_blank" rel="noreferrer noopener">
        </a>
        <div>
          <label>YouBoat</label>
        </div>
      </div>
      }


    </div>
  );
};

export default Gallery;

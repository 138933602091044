import includes from 'lodash/includes';
import { formatWithoutTicket } from './urlHelpers';

const removeTicketParam = (user) => {
  if (user.id && includes(window.location.search, 'ticket=')) {
    window.history.replaceState(null, '', formatWithoutTicket(window.location));
  }
};

export default removeTicketParam;

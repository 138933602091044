import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { http } from '../../../utils/http';
import { selectTeam, setTeam } from '../../../store/team/teamSlice';
import handleApiError from '../../../utils/handleApiError';
import messages from '../../../utils/messages';
import Person from './Person';
import './styles.css';

const fetchTeamData = async (dispatch) => {
  try {
    const team = get(await http.get('/team'), 'data');
    dispatch(setTeam(team));
  } catch (error) {
    handleApiError(error, () =>  dispatch(setTeam({ error: true })));
  }
};

const Team = () => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);

  useEffect(() => {
    fetchTeamData(dispatch);
  }, [dispatch]);

  const showAccountManager = get(team, 'accountManager.name');
  const showSalesRep = get(team, 'salesRep.name');
  const showSupport = get(team, 'support.email') && !showAccountManager && !showSalesRep;

  return (
    <>
      {showAccountManager && <Person role={t(messages.accountManager)} person={team.accountManager}/>}
      {showSalesRep && <Person role={t(messages.salesRep)} person={team.salesRep}/>}
      {showSupport && <Person person={{...team.support, name: t(messages.support)}}/>}
      {!team.accountManager && !team.salesRep && !team.error && <div title={t(messages.pleaseWait)} className="loading"/> }
      {!team.accountManager && !team.salesRep && team.error && <div title={t(messages.error)} className="warning"/> }
    </>
  );
};

export default Team;

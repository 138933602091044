import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { http } from '../../utils/http';
import { login, selectUser } from '../../store/user/userSlice';
import handleApiError from '../../utils/handleApiError';
import AddListing from '../../components/AddListing';
import SeeMyLeads from '../../components/SeeMyLeads';
import Cards from '../../components/Cards';
import Welcome from '../../components/Welcome';
import './styles.css';


// This is a demo action getting user data from a /user API call. This info could also be returned from the /login endpoint.
// We may want to change this as we add more actions.
const fetchUserData = async (dispatch) => {
  try {
    const party = get(await http.get('/user'), 'data');
    dispatch(login(party));
  } catch (error) {
    handleApiError(error, () =>  dispatch(login({ error: true })));
  }
};

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(
    () => {
      fetchUserData(dispatch);
    },
    [dispatch]
  );

  return (
    <div id="home">
      <div id="innerHeader">
        <Welcome user={user}/>
        <div>
          <SeeMyLeads user={user}/>
          <AddListing user={user}/>
        </div>
      </div>
      <Cards user={user}/>
    </div>
  );
};

export default Home;

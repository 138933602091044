import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

const crmStatsSlice = createSlice({
  name: 'crmStats',
  initialState: {},
  reducers: {
    setCRMStats: (state, action) => {
      assign(state, action.payload);
    }
  }
});

export const { setCRMStats } = crmStatsSlice.actions;

export const selectCRMStats = (state) => state.crmStats;

export default crmStatsSlice.reducer;

import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import Footer from './components/Footer';
import Home from './containers/Home';
import translations from './translations';
import { useSelector } from 'react-redux';
import { selectUser } from './store/user/userSlice';
import removeTicketParam from './utils/removeTicketParam';
import './App.css';
import Header from './components/Header';


const App = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    removeTicketParam(user);
  });

  return (
    <HelmetProvider>
      <IntlProvider locale={user.lang} messages={translations[user.lang]} defaultLocale="en">
        <Header user={user}/>
        <Home/>
        <div className="flexZone"/>
        <Footer/>
      </IntlProvider>
    </HelmetProvider>
  );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

const teamSlice = createSlice({
  name: 'team',
  initialState: {},
  reducers: {
    setTeam: (state, action) => {
      assign(state, action.payload);
    }
  }
});

export const { setTeam } = teamSlice.actions;

export const selectTeam = (state) => state.team;

export default teamSlice.reducer;

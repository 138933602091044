import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import './styles.css';

const Card = ({ children, title, otherClasses = '' }) => {
  return (
    <div className={classNames('card', otherClasses, {
      blank: !title
    })}>
      {title && <div className="title">{title}</div>}
      {children && <div className="content">{children}</div>}
    </div>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  otherClasses: PropTypes.string
};

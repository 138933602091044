import { PropTypes } from 'prop-types';

const Stat = ({ label, value }) => {
  return <div className="stat">
    <div className="value">{value}</div>
    <div className="label">{label}</div>
  </div>;
};

export default Stat;

Stat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number
};

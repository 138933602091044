import { configureStore } from '@reduxjs/toolkit';
import crmStatsReducer from './crmStats/crmStatsSlice';
import listingStatsReducer from './listingStats/listingStatsSlice';
import teamReducer from './team/teamSlice';
import userReducer from './user/userSlice';
import galleryReducer from './gallery/gallerySlice';

export const initStore = () => configureStore({
  reducer: {
    crmStats: crmStatsReducer,
    listingStats: listingStatsReducer,
    team: teamReducer,
    user: userReducer,
    gallery: galleryReducer
  }
});

export const store = initStore();

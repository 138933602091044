import { useIntl } from 'react-intl';
import messages from '../../utils/messages';
import './styles.css';

const Footer = () => {
  const intl = useIntl();

  return (
    <div id="footer">
      {intl.formatMessage(messages.copyright, { year: new Date().getFullYear() })}{' '}
      <br className="mobile"/>
      {intl.formatMessage(messages.copyright2)}
    </div>
  );
};

export default Footer;

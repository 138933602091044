import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import messages from '../../../../utils/messages';

const Person = ({ person, role }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  return <div className="person">
    <div className="role">{role}</div>
    <div className="info">
      <div className="rowOne">
        <div className="photo">
          {person.photoUrl && <img src={person.photoUrl} alt={person.name}/>}
        </div>
        <div className="nameEtc">
          <div className="name">{person.name}</div>
          <div className="jobTitle">{person.title}</div>
        </div>
      </div>
      <div className="rowTwo">
        <div className="field"><span className="label">{t(messages.email)}: </span>{person.email && <a href={`mailto:${person.email}`}>{person.email}</a>}
        </div>
        <div className="field"><span className="label">{t(messages.phone)}: </span>{person.phone && <a href={`tel:${person.phone}`}>{person.phone}</a>}
        </div>
      </div>
    </div>
  </div>;
};

export default Person;

Person.propTypes = {
  person: PropTypes.object,
  role: PropTypes.string
};

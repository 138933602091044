import da from './da.json';
import de from './de.json';
import es from './es.json';
import fi from './fi.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import no from './no.json';
import pl from './pl.json';
import ru from './ru.json';
import sv from './sv.json';

const translations = { da, de, es, fi, fr, it, nl, no, pl, ru, sv };

export default translations;

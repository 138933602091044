import { useIntl } from 'react-intl';
import messages from '../../../utils/messages';
import './styles.css';
import { PropTypes } from 'prop-types';

const WhatsNew = ({ lang }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  return (
    <div className="whatsNew">
      <div className="topTip"><span>{t(messages.whatsNewTop)}</span></div>
      <ul>
        <li><span>{t(messages.whatsNewLI1)}</span></li>
        <li><span>{t(messages.whatsNewLI2)}</span></li>
        <li><span>{t(messages.whatsNewLI3)}</span></li>
        <li><span>{t(messages.whatsNewLI4)}</span></li>
        <li><span>{t(messages.whatsNewLI5)}</span></li>
        <li><span>{t(messages.whatsNewLI6)}</span></li>
      </ul>
      <div className="topTip"><a href="https://www.boatsgroup.com/product-updates/" target="_blank" rel="noreferrer noopener">{t(messages.whatsNewP1)}</a></div>
      <div className="topTip paddingTop"><span>{t(messages.whatsNewP2)}</span></div>
      <ul>
        <li><span>{t(messages.whatsNewLI7)}</span></li>
        <li><span>{t(messages.whatsNewLI8)}</span></li>
      </ul>

    </div>
  );
};

WhatsNew.propTypes = {
  lang: PropTypes.string
};

export default WhatsNew;

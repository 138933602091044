import get from 'lodash/get';
import { formatWithoutTicket, redirect } from './urlHelpers';

const { REACT_APP_CAS_LOGIN } = process.env;

const handleApiError = (error, defaultErrorHandler) => {
  if (get(error, 'response.status') === 401) {
    redirect(`${REACT_APP_CAS_LOGIN}?service=${encodeURIComponent(formatWithoutTicket(window.location))}`);
  } else {
    defaultErrorHandler();
  }
};

export default handleApiError;
